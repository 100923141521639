
import { computed, defineComponent,onMounted,ref, watch } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "PublishComment",
  props: {
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props, ctx) {
    function submit() {
      ctx.emit("submit");
    }
    const router = useRouter();
    function onFocus() {
      if (!localStorage.getItem("token")) {
        router.push({
          path: "/login",
          query: {
            redirect: router.currentRoute.value.fullPath,
          },
        });
      }
    }
    const input = ref<HTMLInputElement | null>(null)
    const nativeValue = computed(()=>{
       return props.modelValue === undefined ? '' : props.modelValue
    })
    function setNativeValue() {
      console.log(nativeValue.value);

      (input.value as HTMLInputElement).value = nativeValue.value
    }
    function handleInput(e: Event) {
      ctx.emit("update:modelValue", (e.target as HTMLInputElement).value);
    }

    watch(()=>{
      return props.modelValue
    },()=>{
      setNativeValue()
    })
    onMounted(()=>{
      setNativeValue()
    })


    return {
      handleInput,
      submit,
      onFocus,
      input
    };
  },
});
