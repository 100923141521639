
import { defineComponent, ref, inject } from "vue";
import axios from "@/api/axios";
import { useRoute } from "vue-router";
import { timeFormat } from "@/utils/dateFormat";
import PublishComment from "@/components/common/PublishComment.vue";
import qs from "qs";
import Toast from "@/components/UI/Toast";
import Loading from "@/components/UI/Loading";
import { useRouter } from 'vue-router'
export default defineComponent({
  name: "TopicCommentDetail",
  components: {
    PublishComment,
    Loading
  },
  setup(props, ctx) {

    const route = useRoute();
    const id = route.query.id;
    const entity = ref<any>(null);
    const erjiPostList = ref<object>([]);
    const loadOption = {
      text: "加载中...",
      color: "#b9135a",
      textColor: "#b9135a",
    };
    axios.get("/M/Discover/CommentDetail/" + id).then(res => {
        const data = res.data.obj;
        entity.value = data.entity;
        erjiPostList.value = data.erjiPostList;
    })

    function dateFormat(datetime: string) {
        return datetime.substr(0,16)
    }

    const comment = ref("");
    const my = inject("userInfo") as object;
    const router = useRouter()
    function subComment() {
     const token = localStorage.getItem('token')
      if( !token ){
          Toast({
          type: "warning",
          title: "请先登录！",
        });
        router.push({
          name:'login'
        })
        return false;
      }
      if (!comment.value) {
        Toast({
          type: "warning",
          title: "请先输入评论再提交哦~",
        });
        return false;
      }
      const data = qs.stringify({
        postid: id,
        content: "回复"+ entity.value.user.userName + "：" + comment.value,
      });
      axios
        .post("/M/Server/DoErjiReply", data)
        .then((res) => {
          if (res.data.success) {
            Toast({
              type: "success",
              title: '回复成功',
            });
            (erjiPostList.value as object[]).unshift({
              ...res.data.obj,
              isAlreadyZan: 0,
              user: {
                ...my,
                userImg: (my as any).userImg,
              },
            });
            comment.value = "";
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((err) => {
          const message = err.message ? err.message : "未知错误，请联系管理员";
          Toast({
            type: "error",
            title: message,
          });
        });
    }
    return {
      entity,
      erjiPostList,
      dateFormat,
      timeFormat,
      subComment,
      comment,
      loadOption
    };
  },
});
